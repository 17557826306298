import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout pageInfo={{ pageName: "404" }}>

    <SEO title="404" />

    <div className="grey-bg">
      <Container>
        <Row className="global-padding">
          <Col md={{ span: 8, offset: 2 }}>
            <h2>
              404 - Page not found
            </h2>
            <p className="text-center">The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.</p>

          </Col>
        </Row>
      </Container>
    </div>

  </Layout>
)

export default NotFoundPage
